import { createReducer } from '@reduxjs/toolkit'
import { get, set } from 'lodash-es'
import { AnyAction } from 'redux'
import { FILTER_OPTIONS } from '#/consts/filters'
import * as Actions from '#/store/actions/preferences'
import { GetGeoInfo } from '#/store/actions/user'
import { success } from '#/utils/action'
import { setIn } from '#/utils/object'

export const initialState: TopHap.PreferencesState = {
  places: {
    items: [],
    reason: 'init'
  },
  drawings: [],
  filterMode: 'SIMPLE',
  filter: {
    and: true,
    rental: undefined,
    filters: [{
      and: true,
      subfilters: [
        {
          metric: 'status',
          value: {
            values: ['Active', 'Pending', 'Sold'],
            close_date: { min: 'now-3M/d' }
          }
        }
      ]
    }]
  },
  sort: {
    option: 'status_timestamp',
    dir: 'desc'
  },
  map: {
    mapType: 'color',
    viewport: {
      center: [-122.436452978673, 37.71854821446419],
      zoom: 9,
      pitch: 0,
      bearing: 0,
      bounds: [
        [-122.69074785095899, 37.345827354135196],
        [-122.17576372009975, 38.0975091572424]
      ],
      updatedBy: 'INIT'
    },
    properties: {
      enabled: true,
      labelEnabled: true,
      colorEnabled: false,
      radiusEnabled: false,
      color: 'Price',
      radius: 'LivingSqft',
      label: 'Price'
    },
    descriptive: {
      enabled: true,
      filters: false,
      metric: 'estimate_price',
      hasCloseDate: false,
      closeDate: {
        min: 'now-1M/d',
        max: 'now'
      },
      zoneType: 'auto'
    },
    profitOptions: {
      mode: 'sum',
      docCount: 3,
      soldWithinDays: {
        min: 0,
        max: 180
      },
      ownershipDays: {
        min: 0,
        max: 540
      },
      profit: {
        min: 0,
        max: 4
      }
    },
    estimateOptions: { period: 'past1Y' },
    estimateSoldRatioOptions: { type: 'percent' },
    schoolOptions: { type: 'high' },
    taxOptions: { type: 'total' },
    temperatureOptions: {
      winter: false,
      type: 'average'
    },
    uniqueZonesOptions: { all: true },
    zones: {
      county: true,
      place: true,
      zip: true,
      school: false
    },
    elevations: false,
    timeline: false
  }
}

export const setFilterOption = (
  state: TopHap.PreferencesState,
  action: ReturnType<typeof Actions.SetFilterOption>
) => {
  const { option, value, update } = action.payload
  state.filter = setIn(state.filter, option, value, update)
}

export const setMapOption = (
  state: TopHap.PreferencesState,
  action: ReturnType<typeof Actions.SetMapOption>
) => {
  const { option, value, update } = action.payload
  state.map = setIn(state.map, option, value, update)
}

export const setOption = (
  state: TopHap.PreferencesState,
  action: ReturnType<typeof Actions.SetMapOption>
) => {
  const { option, value, update } = action.payload
  return setIn(state, option, value, update)
}

export const updatePlaces = (
  state: TopHap.PreferencesState,
  action: ReturnType<typeof Actions.UpdatePlaces>
) => {
  state.places = action.payload
}

export const addDrawings = (
  state: TopHap.PreferencesState,
  action: ReturnType<typeof Actions.AddDrawings>
) => {
  state.drawings.push(...action.payload)
}

export const updateDrawing = (
  state: TopHap.PreferencesState,
  action: ReturnType<typeof Actions.UpdateDrawing>
) => {
  const index = state.drawings.findIndex(e => e.id === action.payload.id)
  if (index !== -1) state.drawings[index] = action.payload
}

export const removeDrawing = (
  state: TopHap.PreferencesState,
  action: ReturnType<typeof Actions.RemoveDrawing>
) => {
  state.drawings = state.drawings.filter(e => e.id !== action.payload.id)
}

export const updateStates = (
  state: TopHap.PreferencesState,
  action: ReturnType<typeof Actions.UpdateStates>
) => ({
  ...state,
  ...action.payload
})

export const getGeoInfoSuccess = (
  state: TopHap.PreferencesState,
  action: AnyAction
) => {
  const { geoInfo } = action.payload
  if (get(geoInfo, 'country.iso_code') === 'US') {
    state.map.viewport.center = [geoInfo.location.longitude, geoInfo.location.latitude]
    state.map.viewport.zoom = 10
    state.map.viewport.updatedBy = 'INIT'
  }
}

export const addFilterGroup = (
  state: TopHap.PreferencesState,
  action: ReturnType<typeof Actions.AddFilterGroup>
) => {
  state.filter.filters.push(action.payload)
}

export const clearFilterGroups = (
  state: TopHap.PreferencesState
) => {
  state.filter.rental = undefined
  state.filter.filters = []
}

export const updateFilterGroup = (
  state: TopHap.PreferencesState,
  action: ReturnType<typeof Actions.UpdateFilterGroup>
) => {
  const { groupIndex, and } = action.payload
  state.filter.filters[groupIndex].and = and
}

export const addFilterOption = (
  state: TopHap.PreferencesState,
  action: ReturnType<typeof Actions.AddFilterOption>
) => {
  const { groupIndex, filter, asNewGroup } = action.payload
  if (filter.value === undefined) {
    filter.value = FILTER_OPTIONS[filter.metric].initValue
  }
  if (asNewGroup) {
    state.filter.filters.push({ and: true, subfilters: [filter] })
  } else {
    state.filter.filters[groupIndex!].subfilters.push(filter)
  }
}

export const updateFilterOption = (
  state: TopHap.PreferencesState,
  action: ReturnType<typeof Actions.UpdateFilterOption>
) => {
  const { groupIndex, index, fields, value, operator } = action.payload
  if ('value' in action.payload) {
    if (fields) {
      set(state.filter.filters[groupIndex].subfilters[index], ['value', ...fields.split('.')], value)
    } else {
      set(state.filter.filters[groupIndex].subfilters[index], 'value', value)
    }
  }
  if ('operator' in action.payload) {
    if (fields) {
      set(state.filter.filters[groupIndex].subfilters[index], ['value', ...fields.split('.'), 'operator'], operator)
    } else {
      set(state.filter.filters[groupIndex].subfilters[index], 'operator', operator)
    }
  }
}

export const removeFilterOption = (
  state: TopHap.PreferencesState,
  action: ReturnType<typeof Actions.RemoveFilterOption>
) => {
  const { groupIndex, index } = action.payload
  state.filter.filters[groupIndex].subfilters.splice(index, 1)
  if (!state.filter.filters[groupIndex].subfilters.length) {
    state.filter.filters.splice(groupIndex, 1)
  }
}

export const setFilterMode = (
  state: TopHap.PreferencesState,
  action: ReturnType<typeof Actions.SetFilterMode>
) => {
  state.filterMode = action.payload
  if (action.payload === 'SIMPLE') {
    state.filter = initialState.filter
  }
}

export default createReducer(initialState, {
  [Actions.SetFilterOption.type]: setFilterOption,
  [Actions.SetMapOption.type]: setMapOption,
  [Actions.SetOption.type]: setOption,
  [Actions.UpdatePlaces.type]: updatePlaces,
  [Actions.AddDrawings.type]: addDrawings,
  [Actions.UpdateDrawing.type]: updateDrawing,
  [Actions.RemoveDrawing.type]: removeDrawing,
  [Actions.UpdateStates.type]: updateStates,
  [success(GetGeoInfo)]: getGeoInfoSuccess,
  [Actions.AddFilterGroup.type]: addFilterGroup,
  [Actions.ClearFilterGroups.type]: clearFilterGroups,
  [Actions.UpdateFilterGroup.type]: updateFilterGroup,
  [Actions.AddFilterOption.type]: addFilterOption,
  [Actions.UpdateFilterOption.type]: updateFilterOption,
  [Actions.RemoveFilterOption.type]: removeFilterOption,
  [Actions.SetFilterMode.type]: setFilterMode
})
